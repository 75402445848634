import React from 'react';
import {Box, Link, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {PrimaryColor, SecondaryTextColor} from "../../styles/theme";
import GruppoProlocoFabbricaImg from './../../assets/images/gruppo_proloco.jpg';
import CenaConsiglioImg from './../../assets/images/cena_consiglio.jpg';
import GruppoProlocoFabbrica1Img from './../../assets/images/gruppo_proloco1.jpg';
import Footer from "../core/footer";
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const useStyles = makeStyles({
    root: {
        borderRadius: '8px',
        backgroundColor: `${SecondaryTextColor}F2`,
        border: `3px solid ${PrimaryColor}`,
        padding: '16px'
    },
    imgRoot: {
        marginTop: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    },
    img: {
        maxWidth: '100%',
        padding: '8px',
        marginTop: '32px'
    },
    mailRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: '32px'
    },
    socialRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: '16px'
    },
    socialLink: {
        display: 'inline-block',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        verticalAlign: 'top'
    },
    contactIcon: {
        padding: '16px'
    }
});

const ChiSiamoView = () => {

    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Typography variant="h5">Chi siamo</Typography>
            <Typography mt={2}>
                L' associazione Pro Loco Fabbrica è stata fondata nel 2016 da un gruppo di giovani compresi tra i 20 ed i 30 anni.
                <br/>
                Lo scopo era di ravvivare lo spirito d’iniziativa e di comunità che, purtroppo con gli anni,
                è andato ad affievolirsi sempre di più.
                <br/>
                Per raggiungere questi obiettivi ogni anno vengono organizzate numerose attività incentrate sulla
                valorizzazione e sulla promozione del nostro paese.
                <br/>
                In particolar modo la nostra intenzione e' di sviluppare maggiormente
                i rapporti intergenerazionali, in modo tale da favorire la partecipazione attiva di persone di qualsiasi fascia d’età.
            </Typography>
            <Box mt={6}>
                <Typography>
                    Per qualsiasi informazione o richiesta inviaci una mail e seguici sui nostri canali social.
                </Typography>
                <Box className={classes.mailRoot}>
                    <EmailIcon sx={{marginRight: '16px'}}/>
                    <Link href= "mailto:fabbricaproloco@gmail.com" className={classes.socialLink}>fabbricaproloco@gmail.com</Link>
                </Box>
                <Box className={classes.socialRoot}>
                    <FacebookIcon sx={{marginRight: '16px'}}/>
                    <Link href= "https://www.facebook.com/prolocofabbrica" target="_blank" className={classes.socialLink}>https://www.facebook.com/prolocofabbrica</Link>
                </Box>
                <Box className={classes.socialRoot}>
                    <InstagramIcon sx={{marginRight: '16px'}}/>
                    <Link href= "https://www.instagram.com/prolocofabbrica" target="_blank" className={classes.socialLink}>https://www.instagram.com/prolocofabbrica</Link>
                </Box>
            </Box>
            <Box className={classes.imgRoot}>
                <img
                    src={GruppoProlocoFabbricaImg}
                    className={classes.img}
                    width={512}
                    alt="concerto"
                />
                <img
                    src={CenaConsiglioImg}
                    className={classes.img}
                    width={512}
                    alt="cena 1"
                />
                <img
                    src={GruppoProlocoFabbrica1Img}
                    className={classes.img}
                    width={512}
                    alt="cena 2"
                />
            </Box>
            <Footer/>
        </Box>
    );
};

export {ChiSiamoView};
