import React from 'react';
import {Box} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {PrimaryColor, SecondaryTextColor} from "../../../styles/theme";
import ProlocoFooterWhiteImage from './../../../assets/images/proloco_footer_white.svg'

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 10000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: `${PrimaryColor}E6`,
    },
    mainContainer: {
        width: '250px',
        height: '250px',
        position: 'relative',
        left: 0,
        top: 0
    },
    ldsDualRing: {
        display: 'inline-block',
        width: '250px',
        height: '250px',
        position: 'absolute',
        '&:after': {
            content: `""`,
            display: 'block',
            width: '250px',
            height: '250px',
            borderRadius: '50%',
            border: '6px solid #fff',
            borderColor: `${SecondaryTextColor} transparent ${SecondaryTextColor} transparent`,
            animation: '$lds-dual-ring 1.5s linear infinite'
        },
    },
    '@keyframes lds-dual-ring': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(360deg)'
        }
    },
    logoContainer: {
        backgroundColor: `${SecondaryTextColor}`,
        width: 230,
        height: 230,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        position: 'absolute',
        left: '10px',
        top: '10px'
    }
});


const LoadingView = (props) => {
    const classes = useStyles();
    const box =
        <Box
            className={classes.root}
        >
            <Box className={classes.mainContainer}>
                <Box className={classes.logoContainer}>
                    <img
                        src={ProlocoFooterWhiteImage}
                        width={250}
                        height={125}
                        alt="Loading page"
                    />
                </Box>
                <Box className={classes.ldsDualRing}/>
            </Box>

        </Box>;
    return (props.loading) ? box : null;
};

export {LoadingView};
