import React from 'react';
import {Box, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Typography} from "@mui/material";
import { useMediaQuery } from 'react-responsive';
import ArrivaNatale1Image from "../../../assets/images/arriva_natale1.jpg";
import ArrivaNatale2Image from "../../../assets/images/arriva_natale2.jpg";
import ArrivaNatale3Image from "../../../assets/images/arriva_natale3.jpg";
import ArrivaNatale4Image from "../../../assets/images/arriva_natale4.jpg";
import ArrivaNatale5Image from "../../../assets/images/arriva_natale5.jpg";
import {makeStyles} from "@mui/styles";
import {PrimaryColor, SecondaryTextColor} from "../../../styles/theme";
import Footer from "../../core/footer";

const useStyles = makeStyles({
    root: {
        borderRadius: '8px',
        backgroundColor: `${SecondaryTextColor}F2`,
        border: `3px solid ${PrimaryColor}`,
        padding: '16px',
    },
});

const ArrivaBabboNataleView = () => {

    const classes = useStyles();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
    return(
        <Box className={classes.root}>
            <Typography variant="h5">Arriva Babbo Natale</Typography>
            <Typography variant="body1" mt={2}>
                Avete preparato la letterina? <br/>
                Il nostro mercatino di Natale da la possibilita' a tutti i bambini di consegnare la propria letterina
                direttamente all'autentico Babbo Natale.
            </Typography>
            <ImageList sx={{ width: '100%', height: 'auto', mt: 4 }}>
                <ImageListItem key="Subheader" cols={2}>
                    <ListSubheader component="div"/>
                </ImageListItem>
                {itemData.map((item) => (
                    <ImageListItem key={item.img} cols={(isTabletOrMobile) ? 2 : 1}>
                        <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />

                        {item.title && (
                            <ImageListItemBar
                                title={item.title}
                            />
                        )}

                    </ImageListItem>
                ))}
            </ImageList>
            <Footer/>
        </Box>
    );
}

const itemData = [
    {
        img: ArrivaNatale4Image,
        title: 'Tante letterine per Babbo Natale',
        rows: 1,
        cols: 2,
    },
    {
        img: ArrivaNatale2Image,
    },
    {
        img: ArrivaNatale3Image,
    },
    {
        img: ArrivaNatale5Image,
    },
    {
        img: ArrivaNatale1Image,
    }
];

export {ArrivaBabboNataleView};
