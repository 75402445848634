import * as React from 'react';

import {Typography, Box, IconButton } from '@mui/material';
import {SecondaryTextColor, PrimaryTextColor, PrimaryColor} from "../../../styles/theme";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation} from "react-router-dom";
import { useEffect } from "react";
import ProLogoFooterWhiteImage from "../../../assets/images/proloco_footer_white.svg";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    root: {
        borderRadius: '8px',
        backgroundColor: `${SecondaryTextColor}F2`,
        border: `3px solid ${PrimaryColor}`,
        minHeight: '80px',
        marginBottom: '40px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '16px',
        paddingRight: '16px'
    },
    imgRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    breadCrumbsRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    homeIcon: {
        marginTop: '4px'
    },
    logoImg: {
        marginLeft: '-48px'
    },
    socialRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%',
        flexWrap: 'wrap'
    },
    routeName: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px'
    },
    negativeMarginStart: {
        marginLeft: '-24px !important'
    }
});

const breadCrumbNames = {
    'home': 'Benvenuti!',
    'iniziative': 'Le iniziative principali',
    'ripuliamo-fabbrica': 'Ripuliamo Fabbrica',
    'art-and-food': 'Fabbrica Art & Food',
    'cena-in-strada': 'Cena in strada',
    'festa-ottobre': `Festa d'Ottobre`,
    'arriva-babbo-natale': 'Arriva Babbo Natale',
    'chi-siamo': 'Chi siamo',
    'paese-fabbrica': 'Il paese di Fabbrica'
};

const redirectBackTo = {
    'home': '',
    'iniziative': '/home',
    'ripuliamo-fabbrica': '/iniziative',
    'art-and-food': '/iniziative',
    'cena-in-strada': '/iniziative',
    'festa-ottobre': `/iniziative`,
    'arriva-babbo-natale': '/iniziative',
    'chi-siamo': '/home',
    'paese-fabbrica': '/home'
}



const HeaderView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [routeName, setRouteName] = React.useState('');


    useEffect(() => {
        const newLinks = location.pathname.split('/');
        setRouteName(newLinks[newLinks.length -1]);
    }, [location]);

    const onFbButtonClick = () => {
        window.open('https://www.facebook.com/prolocofabbrica', '_blank');
    };

    const onInstButtonClick = () => {
        window.open('https://www.instagram.com/prolocofabbrica/', '_blank');
    };

    const redirectTo = redirectBackTo[routeName];

    const onRouteBack = () => {
        navigate(redirectTo);
    }

    return (
        <Box className={classes.root}>

            <Box className={classes.imgRoot}>
                {
                    routeName === 'home' ?
                        (<img
                            src={ProLogoFooterWhiteImage}
                            width={200}
                            height={75}
                            alt="Header"
                            className={classes.logoImg}
                        />) :
                        (
                            <IconButton edge="start" onClick={onRouteBack}>
                                <ArrowBackIcon style={{color: `${PrimaryTextColor}`}}/>
                            </IconButton>
                        )
                }

            </Box>

            <Box className={`${classes.routeName} ${(routeName === 'home') ? classes.negativeMarginStart : ''}`}>
                <Typography textAlign="center" marginLeft={1}>
                    {breadCrumbNames[routeName]}
                </Typography>
            </Box>

            <Box className={classes.socialRoot}>
                <IconButton onClick={onFbButtonClick} edge="end">
                    <FacebookIcon fontSize="medium" style={{color: `${PrimaryTextColor}`}}/>
                </IconButton>
                <IconButton onClick={onInstButtonClick} edge="end">
                    <InstagramIcon fontSize="medium" style={{color: `${PrimaryTextColor}`}}/>
                </IconButton>
                {/*<IconButton edge="end">
                    <MenuIcon fontSize="large" style={{color: `${PrimaryTextColor}`}}/>
                </IconButton>*/}
            </Box>

        </Box>
    );
};

export {HeaderView};
