import React from 'react';
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {PrimaryColor, SecondaryTextColor} from "../../styles/theme";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        transition: 'transform .2s',
        "&:hover": {
            transform: 'scale(1.05)'
        },
        borderRadius: '50%',
        overflow: 'hidden',
        width: '290px',
        height: '290px',
        border: `3px solid ${PrimaryColor}`,
        position: 'relative',
        margin: '24px 4px',
        backgroundColor: SecondaryTextColor,
        cursor: 'pointer',
        '-webkit-transform': 'translateZ(0)'
    },
    imageRoot: {
        position: 'absolute',
        height: '142px',
        left: '0',
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        '-webkit-background-position': 'center',
        backgroundPosition: 'center',
        objectFit: 'cover'
    },
    descriptionRoot: {
        position: 'absolute',
        top: '50%',
        left: '0',
        textAlign: 'center',
        padding: '4px 32px',
        width: '100%',
        height: '150px',
        backgroundColor: SecondaryTextColor
    }
});


const CustomCardView = (props) => {

    const classes = useStyles();

    return(
        <Box className={classes.root} onClick={props.onClick}>
            <img
                src={props.image}
                alt={props.alt}
                className={classes.imageRoot}
            />
            <Box className={classes.descriptionRoot}>
                <Typography gutterBottom variant="h6" component="div" color="text.primary" sx={{marginBottom: 0, padding: 0}}>
                    {props.title}
                </Typography>
                <Typography variant="body2" color="text.primary" sx={{padding: 0}}>
                    {props.description}
                </Typography>
            </Box>
        </Box>
    );
}

export {CustomCardView};
