import React from 'react';
import {Box, Modal} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ArtFood2023Image from "../../../assets/images/volantino_fabbrica_artfood_2023.png";

const useStyles = makeStyles({
    root: {},
    mainContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '320px',
        outline: 'none !important'
    },
    logoImg: {
        maxWidth: '300px',
        borderRadius: '8px'
    }
});

const isMajorThanTodayDate = (d1) => {
    let todayDate = new Date().getTime();
    let date1 = new Date(d1).getTime();

    if (todayDate < date1) {
        return true;
    } else return todayDate <= date1;
};



const UltimeNovitaView = () => {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [shown, setShown] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    setTimeout(() => {
        if (!shown) {
            setShown(true);
            if (isMajorThanTodayDate('07/02/2023')) {
                handleOpen();
            }
        }
    }, 2000);


    return(
        <Box className={classes.root}>
            <Modal
                open={open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                onClose={handleClose}
            >
                <Box className={classes.mainContent} onClick={handleClose}>
                    <img
                        src={ArtFood2023Image}
                        alt="Art and Food 2023"
                        className={classes.logoImg}
                    />
                </Box>
            </Modal>
        </Box>
    );
};

export {UltimeNovitaView};
