import React from 'react';
import {Box} from "@mui/material";
import BgImage1 from "../../assets/images/background_image1.jpg";
const bgImages = [BgImage1];

const BackgroundCarouselView = () => {
    const bgIndex = 0;
    const selectedBgImage = bgImages[bgIndex % bgImages.length];

    return (
        <Box sx={{
            backgroundImage: `url("${selectedBgImage}")`,
            position: 'fixed',
            '-webkit-transform': 'translate3d(0, 0, 0)',
            height: '100vh',
            width: '100vw',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            zIndex: '-1'
        }}/>
    );
};

export {BackgroundCarouselView};
