import React from 'react';
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {PrimaryColor, SecondaryTextColor} from "../../styles/theme";
import {Link} from "react-router-dom";
import Footer from "../core/footer";

const useStyles = makeStyles({
    root: {
        borderRadius: '8px',
        backgroundColor: `${SecondaryTextColor}F2`,
        border: `3px solid ${PrimaryColor}`,
        padding: '16px',
    },
    listElRoot: {
        padding: '4px',
        textDecoration: 'underline'
    }
});

const HomeView = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Typography variant="h5">
                Benvenuti nel sito ufficiale della Pro Loco di Fabbrica
            </Typography>
            <Typography variant="body1" mt={2}>
                Qui troverete informazioni riguardanti la nostra associazione, il paese di Fabbrica
                e le iniziative portate avanti negli ultimi anni.
            </Typography>
            <Box>
                <ul>
                    <li className={classes.listElRoot}>
                        <Link to={'/chi-siamo'}>Chi siamo</Link>
                    </li>
                    <li className={classes.listElRoot}>
                        <Link to={'/paese-fabbrica'}>Il paese di Fabbrica</Link>
                    </li>
                    <li className={classes.listElRoot}>
                        <Link to={'/iniziative'}>Le iniziative principali</Link>
                    </li>
                </ul>
            </Box>
            <Footer/>
        </Box>
    );
}

export {HomeView};
