import React from 'react';
import {Box} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ProLogoFooterWhiteImage from './../../../assets/images/proloco_footer_white.svg'
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        marginTop: '64px',
    },
    rootImage: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    image: {
        marginRight: '-16px',
        marginBottom: '-16px'
    }
});

const FooterView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <Box className={classes.root}>
            <Box className={classes.rootImage}>
                <img
                    src={ProLogoFooterWhiteImage}
                    width={250}
                    height={125}
                    alt="Footer"
                    className={classes.image}
                    onClick={() => navigate('/home')}
                />
            </Box>
        </Box>

    );
}

export {FooterView}
