import { createTheme } from '@mui/material/styles';
import {responsiveFontSizes} from "@mui/material";

const PrimaryColor = '#add8e6';
const SecondaryColor = '#BF1522';
const PrimaryTextColor = '#FFFFFF';
const SecondaryTextColor = '#2d4f6b';
const ImageListItemBarBackgroundColor = `${SecondaryTextColor}D9`;

let MainTheme = createTheme({
    palette: {
        primary: {
            main: PrimaryColor
        },
        secondary: {
            main: SecondaryColor
        },
        text: {
            primary: PrimaryTextColor,
            secondary: SecondaryTextColor
        }
    },
    typography: {
        "fontFamily": `"Montserrat", "Arial", sans-serif`,
        h1: {
            color: PrimaryTextColor
        },
        h2: {
            color: PrimaryTextColor
        },
        h3: {
            color: PrimaryTextColor
        },
        h4: {
            color: PrimaryTextColor
        },
        h5: {
            color: PrimaryTextColor
        },
        h6: {
            color: PrimaryTextColor
        },
        body1: {
            color: PrimaryTextColor
        },
        body2: {
            color: PrimaryTextColor
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: SecondaryColor
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: SecondaryTextColor
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    color: `${PrimaryTextColor} !important`,
                    backgroundColor: SecondaryTextColor
                }
            }
        },
        MuiCardActionArea: {
            root: {
                height: '100%'
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: SecondaryTextColor
                }
            }
        },
        MuiImageListItemBar: {
            styleOverrides: {
                root: {
                    background: ImageListItemBarBackgroundColor
                }
            }
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: `${PrimaryTextColor}`
                },
                li: {
                    color: `${PrimaryTextColor} !important`,
                    display: 'flex',
                    alignItems: 'center'
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: `${PrimaryTextColor} !important`
                }
            }
        },
    },

});

MainTheme = responsiveFontSizes(MainTheme);


export {MainTheme, PrimaryColor, SecondaryColor, PrimaryTextColor, SecondaryTextColor};
