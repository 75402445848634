import React from 'react';
import ReactPlayer from "react-player"
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {PrimaryColor, SecondaryTextColor} from "../../styles/theme";
import Footer from "../core/footer";

const useStyles = makeStyles({
    root: {
        borderRadius: '8px',
        backgroundColor: `${SecondaryTextColor}F2`,
        border: `3px solid ${PrimaryColor}`,
        padding: '16px'
    },
    imgRoot: {
        marginTop: '64px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    img: {
        maxWidth: '100%'
    },
    videosRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        paddingBottom: '64px'
    },
    videoWrapper: {
        width: '300px',
        minHeight: '225px'
    },
    youtubeVideoRoot: {
        maxWidth: '100%'
    }
});



const PaeseFabbricaView = () => {

    const classes = useStyles();
    return(
        <Box className={classes.root}>
            <Typography variant="h5">
                Il paese di Fabbrica
            </Typography>
            <Typography mt={2} pb={8}>
                Piccolo borgo situato in Toscana con castellare e antica pieve. Fabbrica si trova in collina alla destra del fiume Era.
                <br/>
                Fu uno dei castelli sotto la giurisdizione politica del comune di Pisa più volte nominato negli atti a partire dal 1284, anno in cui fu assegnato al Capitano della Val d’Era superiore insieme a Latreto, Fabbrica, Montecuccheri, Montecchio Vesponi e Cedri.
                <br/>
                Nel 1400 il paese fu governato dalla famiglia pisana dei Gaetani, a cui si deve la costruzione del castello a quattro torri.
                <br/>
                Si alternarono nel corso dei secoli le nobili famiglie dei Gaetani, dei Ricciardi e dei Gaddi che nel 1685 fecero erigere un nuovo palazzo, sulla cui facciata vennero collocate due iscrizioni dettate dal poeta Giovan Battista Ricciardi, per ricordare le vicende della rocca di Fabbrica.
                <br/>
                Nel XVIII secolo il castello fu demolito completamente e i materiali furono recuperati per costruire le case del borgo.
                <br/>
                Del periodo resta invece la Pieve di Santa Maria Assunta nella tipica architettura romanica.
                <br/>
                Nel 1997 un importante intervento pubblico ha ristrutturato il teatro dedicando l’opera alle ragazze e ai ragazzi che in questo piccolo teatro si innamorarono.
                <br/>
            </Typography >
            <Box className={classes.videosRoot}>
                <Box p={2} className={classes.videoWrapper}>
                    <ReactPlayer
                        url="https://www.facebook.com/watch/?v=1236920227204108&extid=CL-UNK-UNK-UNK-AN_GK0T-GK1C&mibextid=2Rb1fB&ref=sharing"
                        width="100%"
                        height="100%"
                    />
                </Box>
                <Box p={2}>
                    <iframe width="400px" height="280px" className={classes.youtubeVideoRoot}
                            allowfullscreen="allowfullscreen"
                            title="Il paese di Fabbrica"
                            src="https://www.youtube.com/embed/wF6zNSmY4oU">
                    </iframe>
                </Box>

            </Box>
            <Footer/>
        </Box>
    );
};

export {PaeseFabbricaView};
