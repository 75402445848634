import React from 'react';
import {Box, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Typography} from "@mui/material";
import { useMediaQuery } from 'react-responsive';
import SottoILecci1Image from "../../../assets/images/sotto_lecci1.jpg";
import SottoILecci2Image from "../../../assets/images/sotto_lecci2.jpg";
import SottoILecci3Image from "../../../assets/images/sotto_lecci3.jpg";
import SottoILecci4Image from "../../../assets/images/sotto_lecci4.jpg";
import {makeStyles} from "@mui/styles";
import {PrimaryColor, SecondaryTextColor} from "../../../styles/theme";
import Footer from "../../core/footer";

const useStyles = makeStyles({
    root: {
        borderRadius: '8px',
        backgroundColor: `${SecondaryTextColor}F2`,
        border: `3px solid ${PrimaryColor}`,
        padding: '16px'
    },
});

const SottoILecciView = () => {
    const classes = useStyles()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
    return(
        <Box className={classes.root}>
            <Typography variant="h5">Festa d'Ottobre</Typography>
            <Typography variant="body1" mt={2}>
               Una serata all'insegna dell'ottimo cibo e della buona musica.
            </Typography>
            <ImageList sx={{ width: '100%', height: 'auto', mt: 4 }}>
                <ImageListItem key="Subheader" cols={2}>
                    <ListSubheader component="div"></ListSubheader>
                </ImageListItem>
                {itemData.map((item) => (
                    <ImageListItem key={item.img} cols={(isTabletOrMobile) ? 2 : 1}>
                        <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />

                        {item.title && (
                            <ImageListItemBar
                                title={item.title}
                            />
                        )}

                    </ImageListItem>
                ))}
            </ImageList>
            <Footer/>
        </Box>
    );
};

const itemData = [
    {
        img: SottoILecci1Image,
        title: 'I nostri lecci secolari',
        rows: 1,
        cols: 2,
    },
    {
        img: SottoILecci2Image,
    },
    {
        img: SottoILecci3Image,
    },
    {
        img: SottoILecci4Image,
    }
];

export {SottoILecciView};
