import './App.css';
import React, {useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Container, Box} from "@mui/material";
import Header from './components/core/header';
import {ThemeProvider} from '@mui/material/styles';
import {MainTheme} from "./styles/theme";
import MainRoutes from './routes';
import BackgroundCarousel from "./components/background-carousel";
import {useLocation} from 'react-router-dom';
import Loading from "./components/core/loading";
import UltimeNovitaView from "./components/core/ultime-novita";

const App = () => {

    const [loading, setLoading] = React.useState(true);
    const [discoveredRoutes, setDiscoveredRoutes] = React.useState([]);
    const location = useLocation();

    if (loading) {
        setTimeout(() => {
            if (loading) {
                setLoading(false);
            }
        }, 1000);
    }

    useEffect(() => {
       if (location.pathname) {
           if (discoveredRoutes.indexOf(location.pathname) < 0) {
               setLoading(true);
               setDiscoveredRoutes([...discoveredRoutes, location.pathname]);
           }
       }
        window.scrollTo(0,0);
    }, [location, discoveredRoutes]);


    const rootStyle = {
        paddingBottom: 16
    };

  return (
    <div style={rootStyle}>
        <ThemeProvider theme={MainTheme}>
            <BackgroundCarousel/>
            <CssBaseline/>
            <Container sx={{position: 'relative', pr: 1, pl: 1}}>
                <Box sx={{
                    mt:0, mb:4, pt: 2, pb: 2, borderRadius: 2,
                    backgroundColor: `transparent`,
                }}
                >
                    <Header/>
                    <MainRoutes/>
                    <UltimeNovitaView/>
                    <Loading loading={loading}/>
                </Box>
            </Container>
        </ThemeProvider>
    </div>
  );
}

export default App;
