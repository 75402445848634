import React from 'react';
import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import Home from './components/home';
import Iniziative from './components/iniziative';
import ArtAndFood from "./components/iniziative/art-and-food";
import RipuliamoFabbrica from "./components/iniziative/ripuliamo-fabbrica";
import CenaInStrada from "./components/iniziative/cena-in-strada";
import ArrivaBabboNatale from "./components/iniziative/arriva-babbo-natale";
import SottoILecci from "./components/iniziative/sotto-i-lecci";
import Dashboard from "./components/iniziative/dashboard";
import ChiSiamo from "./components/chi-siamo";
import PaeseFabbrica from "./components/paese-fabbrica";

const MainRouter = () => {
    return (
        <Routes>
            <Route path={'home'} element={<Home/>}/>
            <Route path={'chi-siamo'} element={<ChiSiamo/>}/>
            <Route path={'paese-fabbrica'} element={<PaeseFabbrica/>}/>
            <Route path="iniziative" element={<Iniziative/>}>
                <Route index path="" element={<Dashboard/>}/>
                <Route path="art-and-food" element={<ArtAndFood/>}/>
                <Route path="ripuliamo-fabbrica" element={<RipuliamoFabbrica/>}/>
                <Route path="cena-in-strada" element={<CenaInStrada/>}/>
                <Route path="arriva-babbo-natale" element={<ArrivaBabboNatale/>}/>
                <Route path="festa-ottobre" element={<SottoILecci/>}/>
                <Route path="*" element={<Navigate to="" replace />}/>
            </Route>
            <Route path="*" element={<Navigate to="/home" replace />}/>
        </Routes>
    );
};

export default MainRouter;
