import React from 'react';

import { Outlet } from 'react-router-dom';
import {Box} from "@mui/material";

const IniziativeView = () => {
    return (
        <Box>
            <Outlet/>
        </Box>
    );
}

export {IniziativeView};
