import React from 'react';
import {Box, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Typography} from "@mui/material";
import { useMediaQuery } from 'react-responsive'
import Musica1Image from '../../../assets/images/musica1.jpg';
import Musica4Image from '../../../assets/images/musica4.jpg';
import Musica5Image from '../../../assets/images/musica5.jpg';
import Musica7Image from '../../../assets/images/musica7.jpg';
import Musica8Image from '../../../assets/images/musica8.jpg';
import Musica9Image from '../../../assets/images/musica9.jpg';
import MostraFotografica1Image from '../../../assets/images/mostra_fotografica1.jpg';
import MostraFotografica16Image from '../../../assets/images/mostra_fotografica16.jpg';
import MostraFotografica17Image from '../../../assets/images/mostra_fotografica17.jpg';
import MostraFotografica18Image from '../../../assets/images/mostra_fotografica18.jpg';
import ContestPittura2Image from '../../../assets/images/contest_pittura2.jpg';
import ContestPittura3Image from '../../../assets/images/contest_pittura3.jpg';
import ContestPittura4Image from '../../../assets/images/contest_pittura4.jpg';
import ContestPittura6Image from '../../../assets/images/contest_pittura6.jpg';
import ContestPittura8Image from '../../../assets/images/contest_pittura8.jpg';
import ContestPittura9Image from '../../../assets/images/contest_pittura9.jpg';
import ContestPittura11Image from '../../../assets/images/contest_pittura11.jpg';
import StreetFood2Image from '../../../assets/images/street_food2.jpg';
import StreetFood3Image from '../../../assets/images/street_food3.jpg';
import StreetFood5Image from '../../../assets/images/street_food5.jpg';
import StreetFood10Image from '../../../assets/images/street_food10.jpg';
import StreetFood11Image from '../../../assets/images/street_food11.jpg';
import StreetFood12Image from '../../../assets/images/street_food12.jpg';
import MiglioVerdeImage from '../../../assets/images/miglio.jpg';
import {makeStyles} from "@mui/styles";
import {PrimaryColor, SecondaryTextColor} from "../../../styles/theme";
import Footer from "../../core/footer";

const useStyles = makeStyles({
    root: {
        borderRadius: '8px',
        backgroundColor: `${SecondaryTextColor}F2`,
        border: `3px solid ${PrimaryColor}`,
        padding: '16px',
    },
});



const ArtAndFoodView = () => {
    const classes = useStyles();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
    return (
        <Box className={classes.root}>
            <Typography variant="h5">Fabbrica Art & Food</Typography>
            <Typography variant="body1" mt={2}>
                Il nostro evento principale dell'anno nel quale e' presente un concorso di arti visive (Premio Alessandro Ciulli - estemporanea di pittura e concorso fotografico) e uno Streetfood per le vie del paese con spettacoli fino alla sera.
            </Typography>
            <ImageList sx={{ width: '100%', height: 'auto', mt: 4 }}>
                <ImageListItem key="Subheader" cols={2}>
                    <ListSubheader component="div"></ListSubheader>
                </ImageListItem>
                {itemData.map((item) => (
                    <ImageListItem key={item.img} cols={(isTabletOrMobile) ? 2 : 1}>
                        <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />

                        {item.title && (
                            <ImageListItemBar
                                title={item.title}
                            />
                        )}

                    </ImageListItem>
                ))}
            </ImageList>
            <Footer/>
        </Box>
        );
}

const itemData = [
    {
        img: MostraFotografica18Image,
        title: 'Le mostre forografiche per le vie del centro storico',
    },
    {
        img: MostraFotografica1Image
    },
    {
        img: MostraFotografica16Image
    },
    {
        img: MostraFotografica17Image
    },
    /*{
        img: MostraFotografica19Image
    },
    {
        img: MostraFotografica20Image
    },*/
    {
        img: ContestPittura2Image,
        title: `Pittori all'opera`,
    },
   /* {
        img: ContestPittura1Image
    },*/
    /*{
        img: ContestPittura7Image
    },*/
    {
        img: ContestPittura8Image
    },
    {
        img: ContestPittura9Image
    },
    {
        img: ContestPittura11Image
    },
    {
        img: ContestPittura3Image,
        title: 'Premiazioni ai migliori quadri',
    },
    {
        img: ContestPittura4Image
    },
    {
        img: ContestPittura6Image
    },
    {
        img: MiglioVerdeImage
    },
    {
        img: StreetFood2Image,
        title: 'Lo Street Food',
    },
    /*{
        img: StreetFood1Image
    },
    {
        img: StreetFood7Image
    },*/
    {
        img: StreetFood3Image
    },
    /*{
        img: StreetFood9Image
    },*/
    {
        img: StreetFood10Image
    },
    {
        img: StreetFood11Image
    },
    {
        img: StreetFood12Image
    },
    {
        img: StreetFood5Image
    },
    /*{
        img: StreetFood4Image
    },*/
    {
        img: Musica1Image,
        title: 'I nostri eventi musicali',
        rows: 1,
        cols: 2,
    },
    {
        img: Musica8Image,
    },
    {
        img: Musica7Image,
    },
    {
        img: Musica4Image ,
    },
    {
        img: Musica9Image,
    },
    {
        img: Musica5Image,
    },
];

export {ArtAndFoodView};
