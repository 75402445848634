import React from 'react';
import {Typography, Box, ImageListItem, ListSubheader, ImageListItemBar, ImageList} from "@mui/material";
import { useMediaQuery } from 'react-responsive';
import RipuliamoFabbrica1Image from "../../../assets/images/ripuliamo_fabbrica1.jpg";
import RipuliamoFabbrica2Image from "../../../assets/images/ripuliamo_fabbrica2.jpg";
import RipuliamoFabbrica3Image from "../../../assets/images/ripuliamo_fabbrica3.jpg";
import RipuliamoFabbrica4Image from "../../../assets/images/ripuliamo_fabbrica4.jpg";
import RipuliamoFabbrica5Image from "../../../assets/images/ripuliamo_fabbrica5.jpg";
import RipuliamoFabbrica6Image from "../../../assets/images/ripuliamo_fabbrica6.jpg";
import {makeStyles} from "@mui/styles";
import {PrimaryColor, SecondaryTextColor} from "../../../styles/theme";
import Footer from "../../core/footer";

const useStyles = makeStyles({
    root: {
        borderRadius: '8px',
        backgroundColor: `${SecondaryTextColor}F2`,
        border: `3px solid ${PrimaryColor}`,
        padding: '16px',
    },
});


const RipuliamoFabbricaView = () => {

    const classes = useStyles();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
    return (
        <Box className={classes.root}>
            <Typography variant="h5">Ripuliamo Fabbrica</Typography>
            <Typography variant="body1" mt={2}>
                L' iniziativa ha lo scopo di unire una semplice passeggiata ad un modo per ripulire i bordi delle nostre strade.
                La cura del territorio deve sempre prevalere sui nostri splendidi paesaggi.
            </Typography>
            <ImageList sx={{ width: '100%', height: 'auto', mt: 4 }}>
                <ImageListItem key="Subheader" cols={2}>
                    <ListSubheader component="div"></ListSubheader>
                </ImageListItem>
                {itemData.map((item) => (
                    <ImageListItem key={item.img} cols={(isTabletOrMobile) ? 2 : 1}>
                        <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />

                        {item.title && (
                            <ImageListItemBar
                                title={item.title}
                            />
                        )}

                    </ImageListItem>
                ))}
            </ImageList>
            <Footer/>
        </Box>
    );
}

const itemData = [
    {
        img: RipuliamoFabbrica1Image,
        title: 'Soddisfatti del lavoro svolto',
        rows: 1,
        cols: 2,
    },
    {
        img: RipuliamoFabbrica2Image
    },
    {
        img: RipuliamoFabbrica3Image
    },
    {
        img: RipuliamoFabbrica4Image
    },
    {
        img: RipuliamoFabbrica5Image
    },
    {
        img: RipuliamoFabbrica6Image
    }
]

export {RipuliamoFabbricaView};
