import React from 'react';
import {Box, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Typography} from "@mui/material";
import { useMediaQuery } from 'react-responsive';
import CenaInStrada1Image from '../../../assets/images/cena_in_strada1.jpg';
import CenaInStrada2Image from '../../../assets/images/cena_in_strada2.jpg';
import CenaInStrada3Image from '../../../assets/images/cena_in_strada3.jpg';
import CenaInStrada4Image from '../../../assets/images/cena_in_strada4.jpg';
import CenaInStrada5Image from '../../../assets/images/cena_in_strada5.jpg';
import {makeStyles} from "@mui/styles";
import {PrimaryColor, SecondaryTextColor} from "../../../styles/theme";
import Footer from "../../core/footer";

const useStyles = makeStyles({
    root: {
        borderRadius: '8px',
        backgroundColor: `${SecondaryTextColor}F2`,
        border: `3px solid ${PrimaryColor}`,
        padding: '16px',
    },
});


const CenaInStradaView = () => {

    const classes = useStyles();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
    return(
        <Box className={classes.root}>
            <Typography variant="h5">Cena in strada</Typography>
            <Typography variant="body1" mt={2}>
               Una cena aperta a tutti sulle strade del nostro piccolo paese. Una bella serata da condividere per ritrovarsi a tavola tutti insieme.
            </Typography>
            <ImageList sx={{ width: '100%', height: 'auto', mt: 4 }}>
                <ImageListItem key="Subheader" cols={2}>
                    <ListSubheader component="div"></ListSubheader>
                </ImageListItem>
                {itemData.map((item) => (
                    <ImageListItem key={item.img} cols={(isTabletOrMobile) ? 2 : 1}>
                        <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />

                        {item.title && (
                            <ImageListItemBar
                                title={item.title}
                            />
                        )}

                    </ImageListItem>
                ))}
            </ImageList>
            <Footer/>
        </Box>
    );
}

const itemData = [
    {
        img: CenaInStrada3Image,
        title: 'Tutti a tavola',
        rows: 1,
        cols: 2,
    },
    {
        img: CenaInStrada2Image,
    },
    {
        img: CenaInStrada1Image,
    },
    {
        img: CenaInStrada4Image,
    },
    {
        img: CenaInStrada5Image,
    }
];

export {CenaInStradaView}
