import React from 'react';
import {Box} from "@mui/material";
import FabbricaArtAndFoodImage from '../../../assets/images/fabbrica_art_and_food_main1.jpg';
import RipuliamoFabbricaImage from "../../../assets/images/ripuliamo_fabbrica_main_2.jpg";
import ArrivaBabboNataleImage from "../../../assets/images/arriva_babbo_natale_main.jpg";
import FabbricaSottoILecciImage from "../../../assets/images/fabbrica_sotto_i_lecci_main.jpg";
import CenaInStradaImage from "../../../assets/images/cena_in_strada_main.jpg";

import CustomCard from "../../custom-card";
import {useNavigate} from "react-router-dom";

const dashboardCardSettings = {
    'artandfood': {
        image: FabbricaArtAndFoodImage,
        alt: 'Art And Food card',
        title: 'Fabbrica Art & Food',
        description: `L'evento principale dell'anno, il concorso di arti visive, lo Streetfood per le vie del paese e tanto altro ancora.`
    },
    'ripuliamo-fabbrica': {
        image: RipuliamoFabbricaImage,
        alt: 'Ripuliamo Fabbrica card',
        title: 'Ripuliamo Fabbrica',
        description: 'Ripulire i bordi delle nostre strade in compagnia e con una bella passeggiata.'
    },
    'festa-ottobre': {
        image: FabbricaSottoILecciImage,
        alt: `Festa d'Ottobre card`,
        title: `Festa d'Ottobre`,
        description: `Una festa all' insegna dell' ottimo cibo e della buona musica.`
    },
    'arriva-babbo-natale': {
        image: ArrivaBabboNataleImage,
        alt: 'Arriva Babbo Natale card',
        title: 'Arriva Babbo Natale',
        description: `Avete preparato la letterina? Venite a consegnarla nel nostro mercatino a Babbo Natale.`
    },
    'cena-in-strada': {
        image: CenaInStradaImage,
        alt: 'Cena in strada card',
        title: 'Cena in strada',
        description: `Ritrovarsi a tavola per le strade di Fabbrica tutti insieme.`
    },
}

const DashboardView = () => {

    const navigate = useNavigate();

    return (
        <Box pb={8}>
            <Box sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center',flexWrap: 'wrap', marginTop: '40px'}}>
                <CustomCard
                    image={dashboardCardSettings['artandfood'].image}
                    alt={dashboardCardSettings['artandfood'].alt}
                    title={dashboardCardSettings['artandfood'].title}
                    description={dashboardCardSettings['artandfood'].description}
                    onClick={() => navigate('/iniziative/art-and-food')}
                />
                <CustomCard
                    image={dashboardCardSettings['ripuliamo-fabbrica'].image}
                    alt={dashboardCardSettings['ripuliamo-fabbrica'].alt}
                    title={dashboardCardSettings['ripuliamo-fabbrica'].title}
                    description={dashboardCardSettings['ripuliamo-fabbrica'].description}
                    onClick={() => navigate('/iniziative/ripuliamo-fabbrica')}
                />
                <CustomCard
                    image={dashboardCardSettings['cena-in-strada'].image}
                    alt={dashboardCardSettings['cena-in-strada'].alt}
                    title={dashboardCardSettings['cena-in-strada'].title}
                    description={dashboardCardSettings['cena-in-strada'].description}
                    onClick={() => navigate('/iniziative/cena-in-strada')}
                />
                <CustomCard
                    image={dashboardCardSettings['arriva-babbo-natale'].image}
                    alt={dashboardCardSettings['arriva-babbo-natale'].alt}
                    title={dashboardCardSettings['arriva-babbo-natale'].title}
                    description={dashboardCardSettings['arriva-babbo-natale'].description}
                    onClick={() => navigate('/iniziative/arriva-babbo-natale')}
                />
                <CustomCard
                    image={dashboardCardSettings['festa-ottobre'].image}
                    alt={dashboardCardSettings['festa-ottobre'].alt}
                    title={dashboardCardSettings['festa-ottobre'].title}
                    description={dashboardCardSettings['festa-ottobre'].description}
                    onClick={() => navigate('/iniziative/festa-ottobre')}
                />
            </Box>
        </Box>
    );
}

export {DashboardView};
